import axios from "axios";

/**
 * 获取首页的系统排序医生列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSysRankedList = (param) => axios.post("/run/doctor/getSysRankedList", param);

/**
 * 获取付费的医生列表信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getPaidRankList = () => axios.post("/run/doctor/getPaidRankList")

/**
 * 分页查询医生的数据列表
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAllPage = (param) => axios.post("/run/doctor/getAllPage", param);


/**
 * 查询某个医生的详情信息
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getMemo = (param) => axios.post("/run/doctor/getMemo", param);


/**
 * 设置医生的同时最大咨询病人数
 * @param param
 * @returns {Promise<AxiosResponse<T>>}
 */
export const setCurrPatient = (param) => axios.post("/run/doctor/setCurrPatient", param);


/**
 * 获取医生的相关信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getInquiry = () => axios.post("/run/doctor/getInquiry");


/**
 * 执行医生即诊的操作
 * @returns {Promise<AxiosResponse<T>>}
 */
export const setOnSpot = () => axios.post("/run/doctor/setOnSpot");

/**
 * 设置某个医生每个病人的咨询时间
 * @param maxTime
 * @returns {Promise<AxiosResponse<T>>}
 */
export const setDuration = (maxTime) => axios.post("/run/doctor/setDuration", {maxTime: maxTime});
