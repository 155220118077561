<template>
	<el-dialog  :append-to-body="true" :visible="visible" title="官方病例模板详情"   @close='close()'   width="900px" 
	style="margin-top:-13px!important;">  
	  
	  
		 病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，病例模板内容1，
		
		
		
 
 </el-dialog>
  

</template>

<script>
	
	import $ from "jquery"
    import axios from "axios"; 
	import MyDialog from "../../../../components/MyDialog";  
	export default {
		
		name: 'GetCaseTemplateInfoDialog',
		 
		
		components: {
			MyDialog
		},
		data() {
			return {
				case_list:[],
				arsenal:'',
				timeaab:'',
				timeaac:'',
				timeaad:'',
				
				 value: '',
				 value2: '',
				 value3: '',
				 value4: '',
				tabActive: 'first',
				times: '',
				visible: false,
				froms:{
					tableDataNow: [],
					tableDataNext: [],
				},
				obj:{},
				did:0,
			
			}
		}, 
		created(){   
			// const params = new URLSearchParams(); 
			// params.append('uid', sessionStorage.getItem('uid')); 
			// console.log(params);
			// axios.post(this.apiUrl+'/api/doctor/getCaseTemp',params)
			// .then((response) => {    
			// 	this.case_list = response.data.data;  
			// }) 
			// .catch(function(error) {
			//   console.log(error);
			// });   
			
		}, 
		
		
		mounted:function(){
		            this.$nextTick(function(){
		                   $('.time').clockTimePicker({});
		            })
		        },
		
		methods: {
			
			// getInfo(){
			// 	alert(2)
			// }
			
			show(did) { 
				// this.did = did;
				
				// const params2 = new URLSearchParams();
				// params2.append('uid', sessionStorage.getItem('uid')); 
				// params2.append('did', this.did); 
						 
				// axios.post(this.apiUrl+'/api/doctor/getVisitDateValue',params2)
				// .then((response) => {   
				// 	this.froms.tableDataNow = response.data.data.nowDate; 
				// 	this.froms.tableDataNext = response.data.data.nextDate;  
				// }) 
				// .catch(function(error) {
				//   console.log(error);
				// }); 
				
				
				this.visible = true;
			},
			
			close() {
				this.visible = false;
			}
			
		}
		
			
	}
</script>


<style scoped lang="scss">
	
	.imgs li img{
			width: 60px;
		}
		li{
		            list-style-type:none;
		            width:20%;
					height: 20%;
					//background-color: rosybrown;
					float: left;
					text-align: center;
		} 
		
		li img{
			width: 35px;
		}
	
		#tab-4{
			margin-top: 300px!important;
		}
		.el-upload--text{
			
			    border: 1px dashed #d9d9d9;
		}
		.avatar-uploader .el-upload {
		    border: 1px dashed #d9d9d9;
		    border-radius: 6px;
		    cursor: pointer;
		    position: relative;
		    overflow: hidden;
		  }
		  .avatar-uploader .el-upload:hover {
		    border-color: #409EFF;
		    border: 1px dashed #d9d9d9;
		  }
		  .avatar-uploader-icon {
		    font-size: 28px;
		    color: #8c939d;
		    width: 178px;
		    height: 178px;
		    line-height: 178px;
		    text-align: center;
		  }
		  .avatar {
		    width: 178px;
		    height: 178px;
		    display: block;
		  }
		  
	.center {
	  width: 100%;
	  min-height: 1050px;
	  background-color: #ffffff;
	  box-shadow: white;
	
	  .el-aside {
	    .title {
	      height: 100%;
	      -webkit-writing-mode: vertical-lr;
	      writing-mode: vertical-lr;
	      text-align: center;
	      display: block;
	      margin: 14px;
	      font-size: 18px;
	      font-weight: bold;
	      letter-spacing: 10px
	
	    }
	  }
	
	  .el-main {
	    .title {
	
	      small {
	        margin-left: 10px;
	        font-weight: normal;
	      }
	    }
	
	    .el-tabs {
	      height: calc(100% - 60px);
	
	      /deep/ .el-tabs__header {
	        width: 150px;
	      }
	
	      /deep/ .el-tabs__item {
	        margin: 50px 0 50px -1px;
	        color: #000 !important;
	        text-align: center;
	
	        &.is-active {
	          border-left: 4px solid $green;
	        }
	      }
	
	      .el-image {
	        border: 1px solid #979797;
	        margin-top: 20px;
	        width: 150px;
	        height: 150px;
	      }
	
	      .button {
	        margin-top: 150px;
	        padding-left: 50px;
	
	        .el-button {
	          display: block;
	          margin: 10px 0;
	          width: 120px;
	        }
	      }
	
	      .address {
	        & + .el-input {
	          margin-top: 10px;
	        }
	
	        .el-col {
	          padding: 0 5px 0 0;
	
	          &:last-child {
	            padding: 0;
	          }
	        }
	      }
	
	      /deep/ .el-radio__label {
	        font-size: 12px;
	      }
	    }
	  }
	
	
	}
	
	.el-select {
	  width: 100%;
	}
	
	
	
	.tableBox {
		margin-top: 50px;
		position: relative;
		/* background-color: red; */
	}

	.line {
		position: absolute;
		/* position:fixed; */
		z-index: 9;
		top: 0;
		left: 0;
		height: 1px;
		width: 110px;
		background-color: black;
		transform-origin: left;
		transform: rotate(24.2deg);
	}

	.el-slider {
		margin-top: 40px;
	}

	/deep/ .el-slider__marks-text {
		font-size: 12px;
		width: 44px;
	}

	.tips {
		color: $red;
		text-align: right;
		font-size: 12px;
		margin-top: 20px;
	}

	.text {
		margin: 40px 20px;
		font-weight: 500;
	}

	.button {
		text-align: center;

		.el-button {
			min-width: 120px;
		}
	}

	.content {
		height: 100px;
		margin: 20px;
		border: 2px solid $red;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background: #FEF6F4;
		color: $red;
		padding: 20px;

	}
</style>
